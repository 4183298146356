import { Component, Input } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';

@Component({
    selector: 'nps-layout-lite',
    templateUrl: './nps.layout-lite.component.html'
})
export class NpsLayoutLiteComponent /*extends AppLayoutComponent*/ {

  //@Input() containerClass: object = {};

  constructor(layoutService: LayoutService) {
    //super(layoutService);
    layoutService.state.sidebarMenuIconVisible = false;
    layoutService.config.secondLogoText = 'NPS';
    layoutService.config.secondLogoIcon = 'pi-sitemap';
  }
}
