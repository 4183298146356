  <p-menu [model]="items" styleClass="w-full layout-menu border-none">
  <!--<ng-template pTemplate="submenuheader" let-item>
    <span class="text-primary font-bold" *npsPermission="item.permission">{{ item.label }}</span>
  </ng-template>
  <ng-template pTemplate="item" let-item>
    <a pRipple [routerLink]="item.routerLink" *npsPermission="item.permission" class="flex align-items-center p-menuitem-link">
      <span [class]="item.icon"></span>
      <span class="ml-2">{{ item.label }}</span>-->
      <!--<p-badge *ngIf="item.badge" class="ml-auto" [value]="item.badge" />-->
      <!--<span *ngIf="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
        {{ item.shortcut }}
      </span>
    </a>
  </ng-template>-->

</p-menu>
