import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { LayoutService } from '../../../../shared/services/layout.service';
import { enumValues } from '../../../../shared/utils';
import { NetworkRequestDto, NetworkRequestStatusEnum } from '../api/network-request.model';
import { ConfigurationService } from '../../shared/api/configuration.service';
import { NetworkRequestService } from '../api/network-request.service';


@Component({
  templateUrl: './network-request-list.component.html',
  providers: [DialogService, ConfigurationService, ConfirmationService],
  styles: ['']
})
export class NetworkRequestListComponent implements OnInit {

  filterEmails: string[] = [];
  filterProjects: string[] = [];
  filterTenants: string[] = [];
  filterTickets: string[] = [];
  filterStatuses: string[] = enumValues(NetworkRequestStatusEnum);

  networkRequests: NetworkRequestDto[] = [];
  networkRequestsFiltered: NetworkRequestDto[] = [];

  statusEnum = NetworkRequestStatusEnum;

  filters: NetworkRequestListFilter = {};

  constructor(
    private networkRequestService: NetworkRequestService,
    private confirmationService: ConfirmationService,
    private layout: LayoutService,
    public router: Router,
    private route: ActivatedRoute
  ) {
      
  }

  async ngOnInit() {
    await this.refreshList();
  }

  async refreshFilterLists(list: NetworkRequestDto[]) {
    this.filterEmails = [...new Set(list.map(q => q.userEmail))];
    this.filterProjects = [...new Set(list.map(q => q.projectId))];
    this.filterTenants = [...new Set(list.map(q => q.tenantName))];
    this.filterTickets = [...new Set(list.map(q => q.sc3TicketId))];
  }

  filterList() {
    this.networkRequestsFiltered = this.networkRequests
      .filter(p =>
        (!this.filters.projectId || p.projectId === this.filters.projectId)
        && (!this.filters.tenant || p.tenantName === this.filters.tenant)
        && (!this.filters.email || p.userEmail === this.filters.email)
        && (!this.filters.ticket || p.sc3TicketId === this.filters.ticket)
        && (!this.filters.status || p.status === this.filters.status)
      );
  }

  filtersClear() {
    this.filters.projectId = undefined;
    this.filters.tenant = undefined;
    this.filters.email = undefined;
    this.filters.status = undefined;
    this.filters.ticket = undefined;

    this.refreshList();
  }

  async refreshList() {
    this.layout.startLoading();
    this.networkRequestService
      .opNetworkRequests()
      //.opNetworkRequests(this.filters.projectId ?? undefined, this.filters.userId ?? undefined, this.filters.email ?? undefined, this.filters.status ?? undefined)
      .subscribe((response: NetworkRequestDto[]) => {
        this.refreshFilterLists(response);
        this.networkRequests = response
        this.filterList();
        this.layout.stopLoading();
      });
  }

  delete(requestId: string) {
    this.confirmationService.confirm({
      message: 'Do you want to delete this request?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",

      accept: () => {
        this.networkRequestService.opNetworkRequestDelete(requestId)
          .subscribe(() => {
            this.refreshList();
          });
      },
      reject: () => {
      }
    });
  }

  editRequest(requestId: string) {
    this.router.navigate(['./', requestId], { relativeTo: this.route })
  }
  
}


class NetworkRequestListFilter {
  projectId?: string;
  tenant?: string;
  email?: string;
  status?: string;
  ticket?: string;
}
