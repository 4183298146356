<!--requestId: string = uuidv4();
subscriptionId: string = uuidv4();
projectId: string = '';
projectName: string = '';
tenantId: string = uuidv4();
tenantName: string = '';
userId: string = uuidv4();
userEmail: string = '';
status: NetworkRequestStatusEnum = NetworkRequestStatusEnum.Pending;
orchestrationId: string = uuidv4();
creationDateTime: Date = new Date();-->

<p-tabView #nrTab styleClass="mt-3">
  <p-tabPanel header="Network Request">
    @if(form){
    <form [formGroup]="form">
      <div class="grid">
        <div class="col-6">
          <div class="field">
            <label for="requestId" class="block text-900 font-medium mb-2 text-gray-400">Request Id</label>
            <input id="requestId" type="text" formControlName="requestId" readonly pInputText class="w-full mb-3">
          </div>

          <div class="field">
            <label for="sc3TicketId" class="block text-900 font-medium mb-2 ">SC3 Ticket Id *</label>
            <input id="sc3TicketId" type="text" formControlName="sc3TicketId" pInputText class="w-full mb-3">
          </div>

          <div class="field">
            <label for="userEmail" class="block text-900 font-medium mb-2 text-gray-400">User Email</label>
            <input id="userEmail" type="text" formControlName="userEmail" readonly pInputText class="w-full mb-3">
          </div>

          <div class="field">
            <label for="created" class="block text-900 font-medium mb-2 text-gray-400">Created At</label>
            <input id="created" type="text" [value]="networkRequest.creationDateTime|date:'mediumDate'" readonly pInputText class="w-full mb-3">
          </div>

        </div>

        <div class="col-6">

          <div class="field">
            <label for="subscriptionId" class="block text-900 font-medium mb-2">Subscription Id</label>
            <input id="subscriptionId" type="text" formControlName="subscriptionId" pInputText class="w-full mb-3">
          </div>

          <div class="field">
            <label for="projectId" class="block text-900 font-medium mb-2">Project</label>
            <p-dropdown id="projectId" formControlName="projectId" [options]="projects" placeholder="Select a Project"
                        optionLabel="projectName" optionValue="projectId" (onChange)="projectSelected($event)"
                        appendTo="body" styleClass="w-full mb-3" inputStyleClass="w-full" />
          </div>

          <div class="field">
            <label for="tenantName" class="block text-900 font-medium mb-2">Tenant Name</label>
            <input id="tenantName" type="text" formControlName="tenantName" pInputText class="w-full mb-3">
          </div>

          <div class="field">
            <label for="status" class="block text-900 font-medium mb-2">Status *</label>
            <p-dropdown [options]="statuses" formControlName="status" placeholder="Status"
                        [editable]="false" appendTo="body" styleClass="w-full mb-3" inputStyleClass="w-full"></p-dropdown>
          </div>

        </div>

        <div class="text-center col-12">
          <p-button label="Cancel" icon="pi pi-times" class="m-3" routerLink="../"></p-button>
          <p-button label="Save" icon="pi pi-check" class="m-3" [disabled]="!form.valid" (onClick)="save()"></p-button>
        </div>
      </div>
    </form>
    }
    @else{
    <div class="flex justify-content-center">
      <p-progressSpinner></p-progressSpinner>
    </div>
    }
  </p-tabPanel>
  <p-tabPanel header="Questionnaire">
    @for(question of questionnaire.questions; track question.id; let iq = $index){
    <p-panel [header]="question.order + '. ' + question.question + (question.required?' *':'')" [toggleable]="true" styleClass="mb-4">

      @switch (question.questionType) {
      @case ('text'){
      <input type="text" [name]="question.id" pInputText [(ngModel)]="question.answer" styleClass="w-full" class="w-full" #textCtrl="ngModel" readonly [required]="question.required" />
      <small *ngIf="textCtrl.invalid && textCtrl.touched" class="text-orange-500 font-italic">This field is required</small>
      }
      @case ('select'){
      <div *ngFor="let key of getObjectKeys(question.options)" class="field-checkbox">
        <p-radioButton [inputId]="key"
                       [name]="question.id"
                       [(ngModel)]="question.answer"
                       [value]="key" disabled="true" />
        <label [for]="key" class="ml-2">
          {{ question.options[key] }}
        </label>
      </div>
      }
      @case ('multiselect'){
      <div *ngFor="let key of getObjectKeys(question.options)" class="field-checkbox">
        <p-checkbox [label]="question.options[key]" [name]="question.id" [value]="key" [(ngModel)]="question.answer" readonly />
      </div>
      }
      @default{
      <p>Control is Unknown</p>
      }
      }
    </p-panel>
    }
  </p-tabPanel>
</p-tabView>

<p-blockUI [target]="nrTab" [blocked]="formBlocked">
  <i class="pi pi-cloud-upload" style="font-size: 3rem"></i>
</p-blockUI>



