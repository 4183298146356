import { findKeyByValue } from "../utils";

export const Tenant = {
  CARIAD: 'c5f6f6e0-4c59-4aa1-bcd7-033f5f211b1c',
  VWGPROD: '9664fb57-6ab5-4cf3-8c69-2392f2ac06a3',
  VWGST: 'e3932b6c-abdc-42f8-a39c-37516939277f',
  PowerCo: 'a8cf543a-438a-4e26-96a1-a99cfa3c6b07',
  VWGITC: '9f69edf3-ef71-467c-8885-5e380cdc20cd',
}

export type TenantKey = keyof typeof Tenant;

export type TenantDictionary<T> = {
  [key in TenantKey]: T;
};

export function getTenantById(id: string): TenantKey | undefined {
  return findKeyByValue(Tenant, id) as TenantKey | undefined;
}
export function getTenantKeys(): TenantKey[] {
  return Object.keys(Tenant) as Array<TenantKey>;
}
