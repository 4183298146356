import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { NgForm } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { getObjectKeys } from '../../../../shared/utils';
import { NetworkRequestCreateDto, NetworkRequestQuestionnaireDto } from '../api/network-request.model';
import { NetworkRequestService } from '../api/network-request.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { NpsStore } from '../../../services/nps.store';
import { Tenant } from '../../../../shared/models/tenant';

@Component({
  templateUrl: './questionnaire.component.html',
  styles: ['.p-autocomplete-panel {z-index: 1003!important;}']
})
export class QuestionnaireComponent implements OnInit {

  sc3TicketId: string = '';

  @ViewChild('form') form!: NgForm;
  questionnaire: NetworkRequestQuestionnaireDto = new NetworkRequestQuestionnaireDto();
  getObjectKeys = (obj: { [key: string]: string }):string[] => getObjectKeys(obj);
  
  constructor(
    private networkRequestService: NetworkRequestService,
    private notify: NotificationService,
    private npsStore: NpsStore,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      this.sc3TicketId = params['sc3TicketId'];
    });
  }

  async ngOnInit() {
    this.questionnaire = (await lastValueFrom(this.networkRequestService.gpNetworkRequestQuestionnaire()));

    //set ticketId
    var q = this.questionnaire.questions.find(item => item.id === 'sc3TicketId');
    if (q) {
      q.answer = this.sc3TicketId;
    }
  }

  save() {
    if (this.form.valid) {
      //get from extrernal service
      const createDto = new NetworkRequestCreateDto({
        sc3TicketId: this.questionnaire.questions.find(item => item.id === 'sc3TicketId')?.answer,
        orchestrationId: uuidv4(),
        projectId: "project",
        projectName: "project",
        subscriptionId: uuidv4(),
        tenantId: this.npsStore.current.tenant ? Tenant[this.npsStore.current.tenant] : '',
        tenantName: this.npsStore.current.tenant,
        globalDnsTermsOfUseAccepted: true
      });

      //my form data
      createDto.userId = this.npsStore.current.npsUser?.userId ?? uuidv4();
      createDto.userEmail = this.npsStore.current.npsUser?.email ?? '';
      createDto.questionnaire = this.questionnaire;

      this.networkRequestService.gpNetworkRequestCreate(createDto)
        .subscribe(q => {
          this.notify.success('Network Request Created');
          this.router.navigate(['/']);
        });

      console.log(this.questionnaire.questions);
    } 
  }
}

