  <div class="flex mt-2 mb-2 justify-content-between">
    <span class="text-4xl">Network Requests <sup class="p-badge p-badge-info">{{networkRequestsFiltered.length}}</sup></span> 
  </div>

  <div class="flex mt-2 mb-2 justify-content-around">
    <div class="flex-1 mr-2">
      <p-dropdown [options]="filterTickets"
                  [(ngModel)]="filters.ticket"
                  (onChange)="refreshList()"
                  (onClear)="filters.ticket = undefined; refreshList()"
                  [editable]="false" [filter]="true" placeholder="SC3 Ticket" [showClear]="true" appendTo="body" styleClass="w-full" inputStyleClass="w-full"></p-dropdown>
    </div>
    <div class="flex-1 mr-2">
      <p-dropdown [options]="filterProjects"
                  [(ngModel)]="filters.projectId"
                  (onChange)="refreshList()"
                  (onClear)="filters.projectId = undefined; refreshList()"
                  [editable]="false" [filter]="true" placeholder="Project" [showClear]="true" appendTo="body" styleClass="w-full" inputStyleClass="w-full"></p-dropdown>
    </div>
    <div class="flex-1 mr-2">
      <p-dropdown [options]="filterTenants"
                  [(ngModel)]="filters.tenant"
                  (onChange)="refreshList()"
                  (onClear)="filters.tenant = undefined; refreshList()"
                  [editable]="false" placeholder="Tenant" [showClear]="true" appendTo="body" styleClass="w-full" inputStyleClass="w-full"></p-dropdown>
    </div>
    <div class="flex-1 mr-2">
      <p-dropdown [options]="filterEmails"
                  [(ngModel)]="filters.email"
                  (onChange)="refreshList()"
                  (onClear)="filters.email = undefined; refreshList()"
                  [editable]="false" [filter]="true" placeholder="User Email" [showClear]="true" appendTo="body" styleClass="w-full" inputStyleClass="w-full"></p-dropdown>
    </div>
    <div class="flex-1 mr-2">
      <p-dropdown [options]="filterStatuses"
                  [(ngModel)]="filters.status"
                  (onChange)="refreshList()"
                  (onClear)="filters.status = undefined; refreshList()"
                  [editable]="false" placeholder="Status" [showClear]="true" appendTo="body" styleClass="w-full" inputStyleClass="w-full"></p-dropdown>
    </div>
    <div class="">
      <i pButton [rounded]="true" [text]="true" size="small" severity="secondary" icon="pi pi-times" (click)="filtersClear(); "></i>
    </div>
  </div>

  <p-table [value]="networkRequestsFiltered" styleClass="p-datatable-sm w-full"
           [rowHover]="true" sortField="name">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="sc3TicketId">Sc3 Ticket Id</th>
        <th pSortableColumn="projectId">Project Id</th>
        <th pSortableColumn="projectName">Project Name</th>
        <th pSortableColumn="tenantName">Tenant Name</th>
        <th pSortableColumn="email">Email</th>
        <th pSortableColumn="creationDateTime">Creation Date</th>
        <th pSortableColumn="status">Status</th>
        <th class="w-2 text-right"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-i="rowIndex">
      <tr title="{{item.requestId}}" class="cursor-pointer" (dblclick)="editRequest(item.requestId)">
        <td>{{item.sc3TicketId}}</td>
        <td>{{item.projectId}}</td>
        <td>{{item.projectName}}</td>
        <td>{{item.tenantName}}</td>
        <td>{{item.userEmail}}</td>
        <td>{{item.creationDateTime|date:'mediumDate'}}</td>
        <td>
          <i class="pi" [ngClass]="{'pi-question-circle':item.status == statusEnum.Pending, 'pi-check-circle':item.status == statusEnum.Approved, 'pi-times-circle':item.status == statusEnum.Rejected }"></i>&nbsp;{{item.status}}
        </td>
        <td class="text-right">
          <p-button icon="pi pi-pencil" styleClass="p-button-link" [routerLink]="['./', item.requestId]"></p-button>
          <p-button icon="pi pi-times" styleClass="p-button-link" (click)="delete(item.requestId)"></p-button>
        </td>
      </tr>
    </ng-template>
  </p-table>


<p-confirmDialog></p-confirmDialog>
