import { v4 as uuidv4 } from 'uuid';

export enum NetworkRequestStatusEnum {
  // User is rejected from the project
  Rejected = "Rejected",

  // User status is pending
  Pending = "Pending",

  // Request is under review
  Review = "Review",

  // User request is processing
  Processing = "Processing",

  // User is approved to join the project
  Approved = "Approved",

  // User request is completed
  Completed = "Completed"
}



export class NetworkRequestDto {

  // Request ID
  requestId: string = uuidv4(); 

  // My.Serv Ticket ID
  sc3TicketId: string = uuidv4();

  // Azure Subscription Id
  subscriptionId: string = uuidv4(); 

  // Project ID Mesh Cloud Workspace Identifier
  projectId: string = '';

  // Project Name
  projectName: string = '';

  // Project Tenant Id
  tenantId: string = uuidv4(); 

  // Project Tenant Name
  tenantName: string = '';

  // User ID (Entra Id)
  userId: string = uuidv4(); 

  // User Email
  userEmail: string = '';

  // User Status
  status: NetworkRequestStatusEnum = NetworkRequestStatusEnum.Pending;

  orchestrationId: string = uuidv4();

  // Creation Date Time
  creationDateTime: Date = new Date();

  constructor(partial?: Partial<NetworkRequestDto>) {
    Object.assign(this, partial);
  }
}
 

export class NetworkRequestQuestionnaireDto {
  questions: NetworkRequestQuestionDto[] = [];
}

export class NetworkRequestQuestionDto {
  questionType: string = '';
  id: string = '';
  question: string = '';
  answer: string = '';
  required: boolean = false;
  order: number = 0;
  options: { [key: string]: string } = {};

  constructor(partial?: Partial<NetworkRequestQuestionDto>) {
    Object.assign(this, partial);
  }
}

export class NetworkRequestCreateDto extends NetworkRequestDto {
  globalDnsTermsOfUseAccepted: boolean = false;
  questionnaire: NetworkRequestQuestionnaireDto = new NetworkRequestQuestionnaireDto();

  constructor(partial?: Partial<NetworkRequestCreateDto>) {
    super();
    Object.assign(this, partial);
  }
}
