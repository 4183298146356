import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ValidationRule } from '../../../../shared/models/validation-rule';
import { BaseApiService } from '../../../../shared/services/_base-api.service';
import { NetworkRequestCreateDto, NetworkRequestDto, NetworkRequestQuestionnaireDto } from './network-request.model';
import { NpsStore } from '../../../services/nps.store';
import { NpsConfigurationApiUrl } from '../../shared/api/configuration.service';


export const NpsNetworkRequestApiUrl = {
  GpNetworkRequestGetQuestionnaire: () => "/api/requests/networks/questionnaire",
  GpNetworkRequestCreate: () => "/api/requests/networks",
  OpNetworkRequestGetList: () => "/api/operations/requests/networks",
  OpNetworkRequestGetById: (id: string) => `/api/operations/requests/networks/${id}`,
  OpNetworkRequestGetQuestionnaireById: (id: string) => `/api/operations/requests/networks/${id}/questionnaire`,
  OpNetworkRequestUpdate: (id: string) => `/api/operations/requests/networks/${id}`,
  OpNetworkRequestDelete: (id: string) => `/api/operations/requests/networks/${id}`,
  OpNetworkRequestUpdateQuestionnaire: () => `/api/operations/requests/networks/questionnaire`
}

@Injectable({providedIn: 'root'})
export class NetworkRequestService extends BaseApiService{

  constructor(store: NpsStore) {
    super(store.apiUrl);
  }

  getValidators(method: keyof typeof NpsNetworkRequestApiUrl) {
    return super.get<ValidationRule[]>(NpsConfigurationApiUrl.ConfigurationValidators(), { method });
  }

  //#region Network Requests

  gpNetworkRequestQuestionnaire(): Observable<NetworkRequestQuestionnaireDto> {
    return super.get<NetworkRequestQuestionnaireDto>(NpsNetworkRequestApiUrl.GpNetworkRequestGetQuestionnaire());
  }

  opNetworkRequests(projectId: string = '', userId: string = '', email: string = '', status: string = ''): Observable<NetworkRequestDto[]> {
    return super.get<NetworkRequestDto[]>(NpsNetworkRequestApiUrl.OpNetworkRequestGetList(), { projectId, userId, email, status });
  }

  opNetworkRequestGet(id: string): Observable<NetworkRequestDto> {
    return super.get<NetworkRequestDto>(NpsNetworkRequestApiUrl.OpNetworkRequestGetById(id));
  }

  opNetworkRequestGetQuestionnaire(id: string): Observable<NetworkRequestQuestionnaireDto> {
    return super.get<NetworkRequestQuestionnaireDto>(NpsNetworkRequestApiUrl.OpNetworkRequestGetQuestionnaireById(id));
  }

  gpNetworkRequestCreate(post: NetworkRequestCreateDto): Observable<NetworkRequestDto> {
    return super.post(NpsNetworkRequestApiUrl.GpNetworkRequestCreate(), post)
  }

  opNetworkRequestUpdate(id: string, post: NetworkRequestDto): Observable<NetworkRequestDto> {
    return super.put(NpsNetworkRequestApiUrl.OpNetworkRequestUpdate(id), post);
  }

  opNetworkRequestDelete(id: string): Observable<NetworkRequestDto> {
    return super.delete(NpsNetworkRequestApiUrl.OpNetworkRequestDelete(id))
  }

  //#endregion
}
