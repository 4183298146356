
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserProjectMappingDto } from '../../projects/api/user-project-mapping.model';
import { UserProjectMappingService } from '../../projects/api/user-project-mapping.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { NpsStore } from '../../../services/nps.store';

@Component({
  templateUrl: './project-user-register.component.html',
  styles: ['.p-autocomplete-panel {z-index: 1003!important;}']
})
export class ProjectUserRegisterComponent implements OnInit {

  form: FormGroup = this.fb.group(new UserProjectMappingDto());

  constructor(
    private fb: FormBuilder,
    private userProjectMappingService: UserProjectMappingService,
    private notify: NotificationService,
    private npsStore: NpsStore,
    private router: Router
  ) {
  }

  async ngOnInit() {

    let mapping = new UserProjectMappingDto({
      projectId: 'unknown',
      userId: this.npsStore.current.npsUser?.userId ?? '',
      email: this.npsStore.current.npsUser?.email ?? '',
    });

    this.form = this.fb.group(mapping);

    this.form.patchValue(mapping);

    this.addValidators();
  }

  addValidators() {
    this.form.controls["projectId"].addValidators([Validators.required]);
    //  this.form.controls["currency"].addValidators([Validators.required, Validators.minLength(3), Validators.maxLength(3)]);
    //  this.form.controls["transactionGroup"].addValidators([Validators.required, Validators.maxLength(100)]);
    //  this.form.controls["description"].addValidators([Validators.required, Validators.maxLength(250)]);
  }

  save() {
      this.userProjectMappingService.
        projectUserRegister(this.form.value)
        .subscribe(r => {
          if (r) {
            this.notify.success('Saved')
            this.router.navigateByUrl('/nps');
          } else {
            this.notify.error('Failed', r);
          }
        })
  }
}

