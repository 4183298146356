import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../shared/services/notification.service';
import { UserProjectMappingDto } from '../../projects/api/user-project-mapping.model';
import { UserProjectMappingService } from '../../projects/api/user-project-mapping.service';
import { NpsStore } from '../../../services/nps.store';

@Component({
  templateUrl: './new-network-request.component.html',
  styles: ['.p-autocomplete-panel {z-index: 1003!important;}']
})
export class NewNetworkRequestComponent implements OnInit {

  sc3TicketId: string = '';
  form: FormGroup = this.fb.group(new UserProjectMappingDto());
  formBlocked = false;
  constructor(
    private fb: FormBuilder,
    private userProjectMappingService: UserProjectMappingService,
    private notify: NotificationService,
    private npsStore: NpsStore,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      this.sc3TicketId = params['sc3TicketId'];
    });
  }

  async ngOnInit() {

    let mapping = new UserProjectMappingDto({
      projectId: 'unknown',
      userId: this.npsStore.current.npsUser?.userId ?? '',
      email: this.npsStore.current.npsUser?.email ?? '',
    });

    this.form = this.fb.group(mapping);

    this.form.patchValue(mapping);

    this.addValidators();
  }

  addValidators() {
    //  this.form.controls["amount"].addValidators([Validators.required]);
    //  this.form.controls["currency"].addValidators([Validators.required, Validators.minLength(3), Validators.maxLength(3)]);
    //  this.form.controls["transactionGroup"].addValidators([Validators.required, Validators.maxLength(100)]);
    //  this.form.controls["description"].addValidators([Validators.required, Validators.maxLength(250)]);
  }

  cancel() {
    this.router.navigate(['..']);
  }

  save() {
    this.formBlocked = true;
    this.userProjectMappingService.
      projectUserRegister(this.form.value)
      .subscribe({
        next: r => {
          if (r) {
            this.notify.success('Saved')
            this.router.navigate(['..']);
          } else {
            this.notify.error('Failed', r);
          }
          this.formBlocked = false;
        },
        error: e => {
          this.formBlocked = false;
        }
      });
  }
}

