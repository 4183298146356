import { Component, ViewChild } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';
import { NpsSidebarComponent } from "./nps.sidebar.component";

@Component({
    selector: 'nps-layout',
    templateUrl: './nps.layout.component.html'
})
export class NpsLayoutComponent /*extends AppLayoutComponent*/ {

  @ViewChild(NpsSidebarComponent) appSidebar!: NpsSidebarComponent;

  constructor(layoutService: LayoutService) {
    //super(layoutService);
    //layoutService.state.sidebarMenuIconVisible = true;
    layoutService.state.sidebarMenuActive = true;

    layoutService.config.secondLogoText = 'NPS';
    layoutService.config.secondLogoIcon = 'pi-sitemap';
    layoutService.config.secondLogoUrl = '/nps';
  }

    //get npsContainerClass() {
    //  return {
    //    'layout-static-inactive': !this.layoutService.state.sidebarMenuActive 

    //    //    'layout-theme-light': this.layoutService.config().colorScheme === 'light',
    //    //    'layout-theme-dark': this.layoutService.config().colorScheme === 'dark',
    //    //    'layout-overlay': this.layoutService.config().menuMode === 'overlay',
    //    //    'layout-static': this.layoutService.config().menuMode === 'static',
    //    //    'layout-static-inactive': this.layoutService.state.sidebarMenuDesktopInactive && this.layoutService.config().menuMode === 'static',
    //    //    'layout-overlay-active': this.layoutService.state.overlayMenuActive,
    //    //    'layout-mobile-active': this.layoutService.state.sidebarMenuMobileActive,
    //    //    'p-input-filled': this.layoutService.config().inputStyle === 'filled',
    //    //    'p-ripple-disabled': !this.layoutService.config().ripple
    //    }
    //}
}
