import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NpsLayoutLiteComponent } from '../layout/components/nps.layout-lite.component';
import { NpsLayoutComponent } from '../layout/components/nps.layout.component';
import { NetworkRequestEditComponent } from './components/network-request-edit.component';
import { NetworkRequestListComponent } from './components/network-request-list.component';
import { NewNetworkRequestComponent } from './components/new-network-request.component';
import { ProjectUserRegisterComponent } from './components/project-user-register.component';
import { QuestionnaireComponent } from './components/questionnaire.component';

const routes: Routes = [
  {
    path: 'new', component: NpsLayoutLiteComponent,
    children: [
      { path: '', pathMatch: 'full',/*canActivate: [MsalGuard]*/ component: NewNetworkRequestComponent },
      { path: 'questionnaire', pathMatch: 'full',/*canActivate: [MsalGuard],*/ component: QuestionnaireComponent },
      { path: 'register', pathMatch: 'full',/*canActivate: [MsalGuard],*/ component: ProjectUserRegisterComponent },
    ]
  },
  {
    path: 'list', component: NpsLayoutComponent,
    children: [
      { path: '', pathMatch: 'full',/*canActivate: [MsalGuard],*/ component: NetworkRequestListComponent },
      { path: ':requestId', pathMatch: 'full',/*canActivate: [MsalGuard],*/ component: NetworkRequestEditComponent },
    ]
  },
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NetworkRequestRoutingModule { }
