import { Component, ElementRef } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';

@Component({
    selector: 'nps-sidebar',
    templateUrl: './nps.sidebar.component.html'
})
export class NpsSidebarComponent {
    constructor(public layoutService: LayoutService, public el: ElementRef) { }
}

