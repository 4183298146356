
<div class="surface-ground flex justify-content-center align-items-center mt-8">
  <div class="flex flex-column align-items-center justify-content-center">
    <p-card header="User Registration">

      <form [formGroup]="form">
        <div>
          <div class="field">
            <label for="projectId" class="block text-900 font-medium mb-2">Project Id (or use unknown) </label>
            <input id="projectId" formControlName="projectId" type="text" pInputText placeholder="unknown" class="w-full mb-3">
          </div>

          <!--<div class="field">
          <label for="email" class="block text-900 font-medium mb-2">Email *</label>
          <input id="email" formControlName="email" type="text" pInputText placeholder="Email" class="w-full mb-3">
        </div>-->
          <!--<div class="field">
          <label for="meshUsername" class="block text-900 font-medium mb-2">Mesh UserName</label>
          <input id="meshUsername" formControlName="meshUsername" type="text" pInputText placeholder="Mesh User Name" class="w-full mb-3">
        </div>-->

          <div class="text-center">
            <p-button label="Cancel" icon="pi pi-times" class="m-3" routerLink="../"></p-button>
            <p-button label="Register" icon="pi pi-check" class="m-3" [disabled]="!form.valid" (onClick)="save()"></p-button>
          </div>

        </div>
      </form>

    </p-card>
  </div>
</div>
