<div class="flex mt-4 justify-content-center">
  <p-card header="" styleClass="" subheader="For new registration, please help us filling following Questionnaire, in case if you do not know the answers of some questions, please leave them blank, our operation team will fill it along with you.">
    <ng-template pTemplate="header">

    </ng-template>
    <form #form="ngForm" (ngSubmit)="save()">
      <div>
        @for(question of questionnaire.questions; track question.id; let iq = $index){
        <p-panel [header]="question.order + '. ' + question.question + (question.required?' *':'')" [toggleable]="true" styleClass="mb-4">

          @switch (question.questionType) {
          @case ('text'){
          <input type="text" [name]="question.id" pInputText [(ngModel)]="question.answer" styleClass="w-full" class="w-full" #textCtrl="ngModel" [required]="question.required" />
          <small *ngIf="textCtrl.invalid && textCtrl.touched" class="text-orange-500 font-italic">This field is required</small>
          }
          @case ('select'){
          <div *ngFor="let key of getObjectKeys(question.options)" class="field-checkbox">
            <p-radioButton [inputId]="key"
                           [name]="question.id"
                           [(ngModel)]="question.answer"
                           [value]="key" />
            <label [for]="key" class="ml-2">
              {{ question.options[key] }}
            </label>
          </div>
          }
          @case ('multiselect'){
          <div *ngFor="let key of getObjectKeys(question.options)" class="field-checkbox">
            <p-checkbox [label]="question.options[key]" [name]="question.id" [value]="key" [(ngModel)]="question.answer" />
          </div>
          }
          @default{
          <p>Control is Unknown</p>
          }
          }
        </p-panel>
        }
      </div>

      <div class="flex gap-3">
        <p-button label="Cancel" [outlined]="true" class="block w-full mb-4" styleClass="w-full " routerLink="../" />
        <p-button label="Save" [outlined]="true" [disabled]="!form.valid" class="block w-full mb-4" styleClass="w-full" (onClick)="save()" />
      </div>

    </form>
    <ng-template pTemplate="footer">
    </ng-template>
  </p-card>

  </div>
