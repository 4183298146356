import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { enumValues, formAddValidators, getObjectKeys } from '../../../../shared/utils';
import { NetworkRequestDto, NetworkRequestQuestionnaireDto, NetworkRequestStatusEnum } from '../api/network-request.model';
import { UserDto } from '../../shared/api/user-details.model';
import { ValidationRule } from '../../../../shared/models/validation-rule';
import { NetworkRequestService } from '../api/network-request.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { ProjectProfileService } from '../../projects/api/project-profile.service';
import { ProjectProfileDto } from '../../projects/api/project-profile.model';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  templateUrl: './network-request-edit.component.html',
  styles: ['.p-autocomplete-panel {z-index: 1003!important;}']
})
export class NetworkRequestEditComponent implements OnInit {

  requestId!: string;

  networkRequest: NetworkRequestDto = new NetworkRequestDto();
  questionnaire: NetworkRequestQuestionnaireDto = new NetworkRequestQuestionnaireDto();

  getObjectKeys = (obj: { [key: string]: string }): string[] => getObjectKeys(obj);

  roles: string[] = [];
  projects: ProjectProfileDto[] = [];

  usersAutocomplete: UserDto[] = [];

  statuses = enumValues(NetworkRequestStatusEnum);

  form?: FormGroup = undefined;
  validators: ValidationRule[] = [];
  formBlocked = false;

  constructor(
    private fb: FormBuilder,
    private networkRequestService: NetworkRequestService,
    private projectsService: ProjectProfileService, 
    private notify: NotificationService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.validators = await lastValueFrom(this.networkRequestService.getValidators('OpNetworkRequestUpdate'));

    this.route.paramMap.subscribe(params => {
      this.requestId = params.get('requestId') ?? '';
      this.networkRequestService.opNetworkRequestGet(this.requestId)
        .subscribe(r => {
          this.networkRequest = r;
          this.form = formAddValidators(this.fb.group(r), this.validators);
        });

      //get questionnarie
      this.networkRequestService.opNetworkRequestGetQuestionnaire(this.requestId)
        .subscribe(r => {
          this.questionnaire = r;
        });
    });

    this.projectsService.projectProfiles()
      .subscribe(p => this.projects = p);
  }

  projectSelected(event: DropdownChangeEvent) {
    const projectId = event.value;
    const projectDto = this.projects.find(p => p.projectId === projectId);
    this.form?.patchValue({ 'projectName': projectDto?.projectName ?? '' });
  }

  save() {
    this.formBlocked = true;
    this.networkRequest = { ...this.networkRequest, ...this.form?.value };
    this.networkRequestService
      .opNetworkRequestUpdate(this.requestId, this.networkRequest)
      .subscribe(
        {
          next: q => {
            this.notify.success('Network Request Updated');
            this.router.navigate(['..'], { relativeTo: this.route });
            this.formBlocked = false;
          },
          error: e => {
            this.notify.error('Failed to update Network Request');
            this.formBlocked = false;
          }
        })
  }

  cancel() {
    this.router.navigate(['..']);
  }

}

