
<div class="flex mt-6 justify-content-center">

  <p-card #formPanel header="Do you know your Workspace?" styleClass="" subheader="We did not found you in our Database, do you already know the workspace name, which is registered with us?">
    <ng-template pTemplate="header">

    </ng-template>
    <div class="p-8">
      <p-button label="Yes, I know my Workspace" [outlined]="true" class="block w-full mb-4" styleClass="w-full" routerLink="./register" />
      <p-button label="No, I don't know my Workspace and need help" [outlined]="true" [routerLink]="['./questionnaire']" [queryParams]="{sc3TicketId}" class="block w-full mb-4" styleClass="w-full " />
      <p-button label="I am new, I want to register my Workspace" [outlined]="true" [routerLink]="['./questionnaire']" [queryParams]="{sc3TicketId}" class="block w-full mb-4" styleClass="w-full " />
    </div>
    <ng-template pTemplate="footer">
      <div class="text-color-secondary">
        <i class="pi pi-exclamation-triangle"></i> Workspace is the same which you have in Cloud Portal, from where you have ordered the Azure Subscription.
      </div>
    </ng-template>
  </p-card>
  <p-blockUI [target]="formPanel" [blocked]="formBlocked">
    <i class="pi pi-cloud-upload" style="font-size: 3rem"></i>
  </p-blockUI>
</div>
